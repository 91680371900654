import dynamic from 'next/dynamic';
import { useEffect, useState } from 'react';
import Link from 'next/link';

import CloudinaryImage from '../common/CloudinaryImage';

import './homehero.css';
import Container from './Container';
const LazyLoadVideo = dynamic(() => import('./LazyLoadVideo'));

export default function HomeHeroSection({ data, setModalCall = false }) {
  const [windowWidth, setWindowWidth] = useState(0);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <section className="xl:py-[100px] lg:py-[80px] py-[60px] relative bg-[linear-gradient(90deg,_rgb(0,4,2)_0.32%,_rgba(0,4,12,1)_99.75%)]">
      {windowWidth > 768 && data?.bgVideo?.data?.attributes?.url ? (
        <>
          <LazyLoadVideo
            src={data?.bgVideo?.data?.attributes?.url}
            poster="https://res.cloudinary.com/dthpnue1d/image/upload/c_fill,g_faces,w_480/c_scale,q_auto,f_auto,fl_lossy/m_banner_e50d28119d.webp" // Optional thumbnail for before video loads
            type="video/mp4"
          />
          <div className="absolute top-0 bottom-0 left-0 right-0 bg-[rgba(0,0,0,.6)] "></div>
        </>
      ) : data?.bgImage?.data?.attributes?.url ? (
        <CloudinaryImage
          backendImgUrl={data?.bgImage?.data?.attributes?.url}
          className={`absolute top-0 bottom-0 left-0 right-0 w-full z-[0] h-full object-cover`}
          alt={data?.bgImage?.data?.attributes?.alternativeText}
          type="isTablet"
        />
      ) : (
        ''
      )}
      <Container className="relative z-1">
        <div className="grid grid-cols-12 lg:gap-10 gap-4">
          <div className="lg:col-span-10 col-span-12 lg:flex flex-wrap items-center">
            <div>
              {data?.h1_purple && (
                <h1
                  className={`lg:text-[60px] xl:text-[70px] text-[#ffffff] text-[27px] md:text-[32px] font-[700] lg:mb-3 mb-2 lg:leading-[80px] leading-tight shadow-[2px_2px_1px_#0000002e]`}
                >
                  {data?.h1_purple}
                </h1>
              )}
              {data?.p && (
                <p className="xl:text-[24px] lg:text-[16px] text-[14px] lg:font-[400] font-[600] lg:leading-8 leading-4 text-[#FFFFFFE5] max-w-[1000px] lg:mb-8 mb-6">
                  {data?.p}
                </p>
              )}
              <div className="flex md:space-x-6 space-x-2">
                {data?.banner_btn?.map((btn, key) => (
                  <div key={btn?.id}>
                    {btn?.link ? (
                      <Link
                        key={btn?.id}
                        href={`${btn?.link}`}
                        // style={{
                        //   backgroundColor: btn?.color,
                        //   color: btn?.textcolor,
                        // }}
                        className={`${
                          key % 2 === 0
                            ? 'bg-[#0050D5] text-[#fff]'
                            : 'bg-[#ffffff] text-[#000000]'
                        } hover:bg-[#0050D5] group flex h-min items-center justify-center p-0.5 text-center font-medium focus:z-10 blue_btn relative rounded overflow-hidden text-center inline-block shadow-[10px_20px_40px_0px_#00000033]`}
                      >
                        <span className="flex items-center rounded-md text-sm px-4 py-2">
                          <span
                            className={`${
                              key % 2 === 0 ? 'bg-[#ffffff]' : 'bg-[#FFA030]'
                            } absolute top-0 left-0 flex w-full h-0 mb-0 transition-all duration-200 ease-out transform translate-y-0 group-hover:h-full opacity-1`}
                          ></span>
                          <span
                            className={`${
                              key % 2 === 0
                                ? 'group-hover:text-[#000D3A]'
                                : 'group-hover:text-[#ffffff]'
                            } lg:text-[16px] text-[13px] font-[600] relative flex items-center`}
                          >
                            {btn?.text}
                            <svg
                              className={`ml-2 ${
                                key % 2 === 0
                                  ? 'stroke-[white] group-hover:stroke-[black]'
                                  : 'stroke-[black] group-hover:stroke-[white]'
                              }`}
                              width="16"
                              height="16"
                              viewBox="0 0 18 21"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M8.5 1.5L16 10.3684L8.5 19.2368"
                                strokeWidth="2.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M2.09375 1.5L9.59375 10.3684L2.09375 19.2368"
                                strokeWidth="2.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </span>
                        </span>
                      </Link>
                    ) : (
                      <button
                        onClick={() => setModalCall(btn?.text)}
                        className={`${
                          key % 2 === 0
                            ? 'bg-[#0050D5] text-[#ffffff]'
                            : 'bg-[#ffffff] text-[#000000]'
                        } border border-[#0050D5] group flex h-min items-center justify-center relative rounded-[4px] overflow-hidden px-10 lg:py-3 py-2`}
                        id={btn?.text.split(' ').join('')}
                        key={btn?.id}
                      >
                        <span>
                          <span
                            className={`${
                              key % 2 === 0 ? 'bg-[#ffffff]' : 'bg-[#FFA030]'
                            } absolute top-0 left-0 flex w-full h-0 mb-0 transition-all duration-200 ease-out transform translate-y-0 group-hover:h-full opacity-1`}
                          ></span>
                          <span
                            className={`${
                              key % 2 === 0
                                ? 'group-hover:text-[#000000]'
                                : 'group-hover:text-[#000000]'
                            } flex items-center font-[500] relative lg:text-[20px] text-[16px] leading-7`}
                          >
                            {btn?.text}
                          </span>
                        </span>
                      </button>
                    )}
                  </div>
                ))}
              </div>
              {data?.description && (
                <p className="lg:text-[20px] md:text-[16px] sm:text-[16px] font-[600] text-[#FFFFFF] mt-[30px]">
                  {data?.description}
                </p>
              )}
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
}
